import useList from '@/views/hooks/useList'
import store from '@/store'
import ability from '@/libs/acl/ability'

export default function useClientsList() {
  // Table Handlers
  const tableColumns = [
    { label: 'Nom', key: 'nom', sortable: true },
    { label: 'Prénom', key: 'prenom', sortable: true },
  ]

  if (ability.can('update', 'Receptionnaire') || ability.can('delete', 'Receptionnaire')) {
    tableColumns.push({ label: 'Actions', key: 'actions' })
  }

  return {
    tableColumns,
    ...useList({
      fetchAction: () => store.dispatch('receptionnaire/fetchReceptionnaires'),
      deleteActionName: 'receptionnaire/deleteReceptionnaire',
    }),
  }
}
